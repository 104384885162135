html,
body {
  margin: 0;
  padding: 0;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 7.5px !important;
  height: 4.5px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ebbf44 !important;
}

/* Leaflet Fullscreen */

.leaflet-control-fullscreen a {
  background: #fff url(/public/fullscreen.png) no-repeat 0 0;
  background-size: 26px 52px;
}
.leaflet-touch .leaflet-control-fullscreen a {
  background-position: 2px 2px;
}
.leaflet-fullscreen-on .leaflet-control-fullscreen a {
  background-position: 0 -26px;
}
.leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
  background-position: 2px -24px;
}

.leaflet-container {
  line-height: 0.5;
  font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

/* Zoom Conrols */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #f0f0f0;
  color: #dd9700;
}

/* Marker */

.leaflet-marker-icon:hover {
  background: none;
  border: none;
  box-shadow: none;
}

/* Controls */

.leaflet-top.leaflet-left {
  left: 10px;
  top: 100px;
}

.leaflet-control-layers-toggle {
  background-size: 20px 20px;
  width: 35px;
  height: 35px;
}

.leaflet-control-layers.leaflet-control {
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.leaflet-control-layers.leaflet-control:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.leaflet-control-layers-list img {
  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
}

.leaflet-control-layers-list label span {
  display: flex;
  flex-direction: row;
}

.cluster-icon {
  background-color: #b15400;
  height: 30px;
  width: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  box-shadow: 0 0 0px 2px #fff;
  cursor: pointer;
  &.selected {
    background-color: #dd9700;
  }
}

.cluster-icon:hover {
  background-color: #ebbf44;
}

.heading {
  color: #dd9700;
}

.control-layer-option {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 75px;
  height: 75px;
  border-radius: 9px;
  margin: 5px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.466);
}